import isNull from 'lodash/isNull';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { isNewHomePageApplicable } from '../constants/feature';
import CustomFilter from './CustomFilter';


const DesktopFilter = ({
  filterList = [],
  applyFilters: applyFiltersCopy,
  deviceType,
  isFilterApplied = false,
  children,
  className,
  shadowHeight = 590,
  isDealsPage = false
}) => {
  const router = useRouter()
  const [filterListState, setFilterListState] = useState([...filterList]);
  const [filterHolderWidth, setfilterHolderWidth] = useState(0)
  const [open, setOpen] = useState(null)
  const [popupMenuIndex, setPopupMenuIndex] = useState(null)
  const [addShadow, setAddShadow] = useState(false)

  const filterHolder = useRef(null);

  const handleOpen = (index) => {
    if (open === index) return setOpen(null)
    return setOpen(index)
  }

  useEffect(() => {
    setfilterHolderWidth(filterHolder.current.clientWidth);
  }, [filterList])

  const scrollToFilter = () => {
    let header = document.getElementsByClassName('bento-header')[0]
    let filter = document.getElementsByClassName("desktop-filter-container")[0]
    if (filter?.getBoundingClientRect().top > header?.clientHeight) {
      if (filter?.getBoundingClientRect().top - 37 - 20 > header?.clientHeight) return // 37px is advert header height & 20px is buffer
    };

    var element = document.getElementById('list-top');
    var headerOffset = (header?.clientHeight + filter?.clientHeight) || 150;
    var elementPosition = element?.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
    });
  }

  const debouncedOnchange = useRef(debounce((filters) => {
    applyFiltersCopy(filters)
  }, 1000, { leading: false, trailing: true }))

  function applyFilters(filters) {
    debouncedOnchange.current(filters)
  }

  useEffect(() => {
    localStorage.setItem("isPopupOpen", !isNull(open))
  }, [open])

  const clearFilters = () => {
    const filters = [...filterListState];

    const tempFilters = filters.map(item => {
      let filter = { ...item };

      filter.values = filter.values.map((subItem, index) => {
        let optionValue = { ...subItem };
        if (optionValue.type === "checkbox") {
          optionValue.checked = false;
        } else if (optionValue.type === "radio") {
          delete optionValue.intended
          optionValue.checked = index === 0
        } else {
          optionValue = optionValue;
        }
        return optionValue;
      });
      return filter;
    });

    setFilterListState(tempFilters);
    applyFiltersCopy(tempFilters)
    setOpen(null)
    scrollToFilter()
  };

  const onChange = (event, type) => {
    const { name, value, checked } = event.target;

    const filters = filterListState.map(item => {
      let filter = { ...item };
      if (filter.name === name) {
        filter.values = filter.values.map((subItem) => {
          let optionValue = { ...subItem };

          if (type === "checkbox") {
            optionValue.checked = optionValue.value === value ? checked : optionValue.checked;
          } else {
            optionValue.intended = true;
            optionValue.checked = optionValue.value === value ? checked : !checked
          }
          return optionValue;
        });
      }
      return filter;
    });

    setFilterListState(filters);
    applyFilters(filters);
    scrollToFilter()
  };

  const handlePopup = (e) => {
    const parent = document.getElementsByClassName('desktop-filters')[0]
    const child = e.target.parentElement;

    if (child?.id === "popup-open-btn" || child?.id === "popup-open-btn-icon") return
    if (!JSON.parse(localStorage.getItem("isPopupOpen"))) return
    if (child?.className?.split(" ").includes("custom-checkbox")) return;
    if (parent?.contains(child)) return;
    setOpen(null)
  }

  useEffect(() => {
    window.addEventListener('click', handlePopup);
    return () => {
      window.removeEventListener('click', handlePopup);
    };
  }, []);

  useEffect(() => {
    setFilterListState([...filterList]);
  }, [filterList]);

  useEffect(() => {
    if (!open || open !== popupMenuIndex) {
      let list = filterListState.map((filter, index) => {
        let tempFilter = filter;
        const { name = "", values = [], title = '' } = tempFilter;
        if (name === "product_price") return tempFilter;

        // Ordering based on the array has checked items or not
        const hasCheckedItems = values.some(item => item.checked)
        if (hasCheckedItems) {
          const checked = values.filter(item => item.checked)
          const unchecked = values.filter(item => !item.checked)
          tempFilter.values = [...checked, ...unchecked]
        } else {
          let orderedList = [...values]
          if (name === "brands") {
            orderedList.sort((a, b) => b.count - a.count)
          } else if (name === "deviceTypes") {
            orderedList.sort((a, b) => parseInt(a.id) - parseInt(b.id))
          } else if (title === "Categories") {
            orderedList.sort((a, b) => a?.order - b?.order)
          }
          else {
            orderedList.sort((a, b) => parseInt(a.id.split("-")[1]) - parseInt(b.id.split("-")[1]))
          }
          tempFilter.values = [...orderedList]
        }
        return tempFilter;
      })
      setFilterListState(list)
    }
    setPopupMenuIndex(open)
  }, [open])

  return (
    <div className={`desktop-filter-container mt-3 hide-for-drinkcann hide-for-kiva hide-for-plus-products hide-for-zenodose ${className} ${isNewHomePageApplicable ? "extra-sticky" : ""} ${isNewHomePageApplicable && addShadow ? "sticky-filter-shadow" : ""}`} style={{ '--filter-holder-width': filterHolderWidth + 'px' }} key='desktop-filter-container'>
      <div className='d-inline-flex align-items-center justify-content-center text-left filters-holder' ref={filterHolder}>
        <h5 className="bold mb-0 d-none">
          Filters
        </h5>
        <div className="desktop-filters d-flex" >
          <>
            {filterListState.map((item, index) => {
              const { title = "", name } = item;
              if (name === 'priceRange') return;
              if (title.toLowerCase() === "brands" && (router.pathname.includes("/brand") || router.pathname.includes("/brands"))) return;
              if (!isDealsPage && (title.toLowerCase() === "categories" && (router.pathname.includes("/category") || router.pathname.includes("/categories")))) return;
              if (!item?.values.length) return null;

              return (
                <CollapsibleFilter
                  filter={item}
                  deviceType={deviceType}
                  onChange={onChange}
                  handleOpen={() => handleOpen(index)}
                  open={open === index}
                />
              )
            })}
            <button disabled={!isFilterApplied} type="button" className="btn btn-link clear-btn semi-bold ml-2" onClick={clearFilters}>
              Clear All
            </button>
          </>
        </div>
        {children}
      </div>
    </div>
  );
};

const CollapsibleFilter = ({ filter, deviceType, onChange, open, handleOpen }) => {
  const { title, type, name = "", values = [] } = filter;

  const selectedFiltersCount = useMemo(() => {
    return values.reduce((total, option) => {
      if (option.checked) total++;
      return total;
    }, 0)
  }, [values])

  return (
    <div className="filters-block">
      <div
        id="popup-open-btn"
        onClick={handleOpen}
        className='d-flex align-items-center justify-content-between'
        style={{ cursor: "pointer" }}
      >
        <div className="mr-3">
          {title}
          {name !== "product_price" && selectedFiltersCount !== 0 ? (<span className="filter-count"> {selectedFiltersCount}</span>) : ""}
        </div>
        <i id='popup-open-btn-icon' className={open ? 'icon-down flip' : 'icon-down '} />
      </div>
      {open ? (
        <CustomFilter
          id="custom-filter-list"
          title={title}
          type={type}
          deviceType={deviceType}
          name={name}
          options={values}
          onChange={onChange}
        />
      ) : null}
    </div>
  )
}

export default DesktopFilter;
